<template>
    <el-container>
        <el-main>
          <h2 class="depTitle">科室人员信息管理</h2>
          <div class="RightDiv">
            <el-button type="primary" @click="AddUser">添加科室人员</el-button>
          </div>
          <el-table :data="UsertableData" border center>
            <el-table-column type="index" label="序号" width="55" align="center">
            </el-table-column>
            <el-table-column prop="nickName" label="姓名" align="center">
            </el-table-column>
            <el-table-column prop="phonenumber" label="手机号" align="center">
            </el-table-column>
            <el-table-column label="操作" align="center" width="200">
              <template style="display: flex" slot-scope="scope">
                <el-button size="mini" type="primary" @click="EditUser(scope.row)"><i class="el-icon-edit"></i></el-button>
                <el-button size="mini" type="danger" @click="DeleteUser(scope.row)"><i class="el-icon-delete-solid"></i></el-button>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination background @current-change="handleCurrentUsertabChange" :current-page="pageUserNum" :page-size="pageUserSize" layout="  prev, pager, next" :total="totalUser">
          </el-pagination>
          <!-- 点击添加科室弹出框 -->
          <el-dialog :title="addDepTit" :visible.sync="AdddialogVisible" width="400px" :before-close="AdddialogVisibleClose">
            <el-form :model="AddUserModel" ref="AddUserRef" :rules="AddUserRules" :inline="true" label-width="100px">
              <el-form-item label="姓名：" prop="nickName">
                <el-input v-model="AddUserModel.nickName" placeholder="请填写姓名"></el-input>
              </el-form-item>
              <el-form-item label="手机号：" prop="phonenumber" class="shoujihao">
                <el-input type="phone" v-model="AddUserModel.phonenumber" placeholder="请填写手机号"></el-input>
              </el-form-item>
              <el-form-item class="depar-dia">
                <div class="btnCen">
                  <el-button v-if="adduserClick" type="primary" :loading="adduserLoading"  @click="addKeshi">提交</el-button>
                  <el-button v-else type="info" :loading="adduserLoading"></el-button>
                </div>
              </el-form-item>
            </el-form>
          </el-dialog>
        </el-main>
      </el-container>
</template>

<script>
import _qs from "qs";
export default {
  data() {
    var checkAge = (rule, value, callback) => {
      setTimeout(() => {
        if (value && !/^1[3|4|5|7|8][0-9]\d{8}$/.test(value)) {
          callback(new Error("请输入正确的手机号"));
        } else {
          callback();
        }
      }, 100);
    };
    return {
      addDepTit:'添加科室',
      // 科室用户管理表格
      UsertableData: [],
      // 当前页数
      pageUserNum: 1,
      // 每页显示条数
      pageUserSize: 10,
      totalUser: 1,
      departmentId: window.sessionStorage.getItem("departmentId"),
      hospitalId: window.sessionStorage.getItem("hospitalId"),
      // 点击添加科室弹出框
      AdddialogVisible: false,
      // 添加科室用户
      AddUserModel: {
        // 名称
        nickName: "",
        // 手机号
        phonenumber: "",
        // 是否有数据维护 0 选择 1 不选
        dataAuth: "1",
      },
      AddUserRules: {
        nickName: [
          {
            required: true,
            message: "请输入科室用户名称",
            trigger: "blur",
          },
        ],
        phonenumber: [
          { required: true, validator: checkAge, trigger: "blur" },
        ],
      },
      // 添加科室的添加按钮
      adduserClick: true,
      adduserLoading: false,
      userId:""
    };
  },
  created() {
    this.getDepartmentUserList()
  },
  methods: {
    // 修改科室用户
    EditUser(row) {
      this.addDepTit='修改科室'
      this.AdddialogVisible = true;
      this.userId=row.userId
      Object.assign(this.AddUserModel,row)
    },
    // 删除科室用户
    async DeleteUser(row) {
      const confirmResult = await this.$confirm("确认删除？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).catch((err) => err);

      if (confirmResult !== "confirm") {
        return this.$message.info("已经取消删除");
      }
      let data = _qs.stringify({
        userIds: row.userId,
      });
      let { data: res } = await this.$axios.removeDepartmentUser(data);
      // console.log(res);
      if (res.code == 401) {
        this.$router.push("/login");
      } else if (res.code == 500) {
        this.$message({
          message: res.msg,
          type: "error",
        });
      } else if (res.code == 200) {
        this.$message({
          message: res.msg,
          type: "success",
        });
        this.getDepartmentUserList();
      }
    },
    async getDepartmentUserList() {
      let data = {
        hospitalId: this.hospitalId, //科室id
        departmentId: this.departmentId, //科室id
        pageNum: this.pageUserNum, //页数
        pageSize: this.pageUserSize, //每页显示条数
      };
      let { data: res } = await this.$axios.getDepartmentUserList(data);
      // console.log(res);
      if (res.code == 401) {
        this.$router.push("/login");
      } else if (res.code == 200) {
        this.UsertableData = res.rows;
        this.totalUser = res.total;
      }
    },
    // 科室用户管理分页
    handleCurrentUsertabChange(val){
      this.pageUserNum = val;
      this.getDepartmentUserList();
    },
    // 点击添加科室用户
    AddUser() {
      this.addDepTit='添加科室'
      this.AdddialogVisible = true;
    },
    // 点击添加科室的时候取消正则
    AdddialogVisibleClose() {
      this.AdddialogVisible = false;
      this.addDepTit='添加科室'
      this.AddUserModel= {
        // 名称
        nickName: "",
        // 手机号
        phonenumber: "",
        // 是否有数据维护 0 选择 1 不选
        dataAuth: "1",
      }
      this.userId=''
      this.getDepartmentUserList()
    },
    // 点击操作添加科室的添加
    addKeshi() {
      this.$refs.AddUserRef.validate(async (valid) => {
        if (!valid) return;
        this.adduserClick = false;
        this.adduserLoading = true;
        let data
        if(this.addDepTit=='修改科室'){
          data = _qs.stringify({
            nickName: this.AddUserModel.nickName, //名称
            phonenumber: this.AddUserModel.phonenumber, //手机号
            dataAuth: this.AddUserModel.dataAuth, //是否有数据维护 0 选择 1 不选
            userId: this.userId, //用户id
            departmentId: this.departmentId, //科室id
          });
        }else if(this.addDepTit=='添加科室'){
          data = _qs.stringify({
            nickName: this.AddUserModel.nickName, //名称
            phonenumber: this.AddUserModel.phonenumber, //手机号
            dataAuth: this.AddUserModel.dataAuth, //是否有数据维护 0 选择 1 不选
            departmentId: this.departmentId, //科室id
          });
        }

        this.addAndEdit(data);
      });
    },
    async addAndEdit(data) {
      let res = await this.$axios.addAndEdit(data);
      // console.log(res);
      this.adduserClick = true;
      this.adduserLoading = false;
      if (res.data.code == 500) {
        this.$message({
          message: res.data.msg,
          type: "error",
        });
      } else if (res.data.code == 401) {
        this.$router.push("/login");
      } else if (res.status == 200) {
        this.$message({
          message: res.data.msg,
          type: "success",
        });
        this.getDepartmentUserList();
        this.AdddialogVisibleClose()
      }
    },
  },
};
</script>

<style lang="less" scoped>
  .RightDiv {
    float: right;
    margin-bottom: 20px;
  }

  .searForm {
    float: right;
  }

  .depTitle {
    display: flex;
    margin: 0 auto;
    margin-bottom: 20px;
  }

  .depart-addnum {
    width: 100%;
  }

  .el-pagination {
    margin-top: 20px;
  }

  .btnCen {
    .el-button {
      width: 150px;
      height: 40px;
    }
  }

  /deep/ .depar-dia .el-form-item__content {
    margin-left: 0px !important;
  }

  /deep/ .el-form-item.shoujihao.is-required:not(.is-no-asterisk)>.el-form-item__label:before{
    display: none;
  }
</style>
